export const isMobile = () => {
    return window.innerWidth < 1024;
};

export const fileName = (file = '') => {
    file = file ? file.replace(/\\/g, '/') : '';
    return file ? file.substr(file.lastIndexOf('/') + 1) : file;
};

export const imageSrc = (picture = '') => {
    return picture ? `${ process.env.VUE_APP_STATIC_URL }/${ picture.replace(/\\/g, '/') }` : '';
};

export const checkUserProfile = (user = {}) => {
    return (user._id && user.firstname && user.lastname && user.avatar && user.about);
};

export const priceFormated = (price, currency) => {
    return price ? new Intl.NumberFormat('ru-RU').format(price / 100) + ' ₽' : 'Бесплатно';
};
export const priceFormatedFrom = (price, currency) => {
    return 'от ' + (price ? new Intl.NumberFormat('ru-RU').format(price / 100) + ' ₽' : '0 ₽');
};

export const weightFormated = (weight) => {
    return `${ +weight } г`;
};

export const distanceFormated = (distance) => {
    if(distance > 1000) {
        return (+distance / 1000) + ' км';    
    }
    return +distance + ' м';
};

export const scheduleShortFormated = (weekday, hours, minutes) => {
    const weekdays = {
        1: 'Пн',
        2: 'Вт',
        3: 'Ср',
        4: 'Чт',
        5: 'Пт',
        6: 'Сб',
        0: 'Вс',
    };

    return `${ weekdays[weekday] } ${ hours.toString().length === 1 ? '0' + hours : hours }:${ minutes.toString().length === 1 ? '0' + minutes : minutes }`;
};

export const scheduleFormated = (weekday, hours, minutes) => {
    const weekdays = {
        1: 'Понедельник',
        2: 'Вторник',
        3: 'Среда',
        4: 'Четверг',
        5: 'Пятница',
        6: 'Суббота',
        0: 'Воскресенье',
    };

    return `${ weekdays[weekday] } ${ hours.toString().length === 1 ? '0' + hours : hours }:${ minutes.toString().length === 1 ? '0' + minutes : minutes }`;
};

export const htmlFormatted = (text) => {
    return text ? text.replace(/\n/g, '<br>') : '';
};

export const translit = (word = {}) => {
    var converter = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
        'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya'
    };

    word = word.trim().toLowerCase();

    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
        if (converter[word[i]] == undefined){
            answer += word[i];
        } else {
            answer += converter[word[i]];
        }
    }

    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/^\-|-$/g, '-'); 
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/\-/g, ' ');
    answer = answer.trim();
    answer = answer.replace(/ /g, '-');
    return answer;
};

export const generateCode = length => {
    const allowedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var code = '';
    for (var i = 0; i < length; i++) {
        code += allowedValues[getRand(allowedValues.length)];
    }
    return code;
}

const getRand =  max => {
    return Math.floor(Math.random() * max);
}